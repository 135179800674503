defineDs('DanskeSpil/Domain/Lotto/Scripts/Components/GameClients/LottoLuckyClient',
  [
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Lucky/Lucky',
    'DanskeSpil/Domain/Lotto/Scripts/Models/LottoGame',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
  ],
  function (Params, NumberGamesLucky, LottoGame, LottoInfo) {

    // Client:
    var GameClient = function (m, settings, property) {

      // Components:
      var root = {
        controller: function () {

          this.settings = settings;
          this.property = property;

          // Variables:
          this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
          this.game = m.prop(LottoGame.setupGame({ gameInstanceId: this.gameInstanceId(), savedCoupon: settings.savedCoupon, playType: 'Lucky' }));
          this.property('currentRow', 1);

          // Replace state:
          if (!this.gameInstanceId() != this.game().id()) {
            Params.set('gameInstanceId=' + this.game().id());
          }

          // Tracking - Push productDetails event to dataLayer
          this.game().trackingProductDetails();

        },

        view: function (controller) {
          return (<NumberGamesLucky
            rootSettings={settings}
            rootProperty={property}
            gameInstance={controller.game}
            gameModel={LottoGame}
            gameName={'lotto'}
            rowsOptions={settings.luckyRowOptions || LottoInfo.saturday().getLuckyRowOptions()}
            defaultRowCount={LottoInfo.saturday().getLuckyDefaultRowCount()}
          />);
        }
      };

      return root;

    };

    // Public functions:
    return GameClient;

  });

defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Components/CustomPackage',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'Common/Framework/EventHandling/Scripts/AppEvent',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Confirm',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/Shared/PopoverDrawer',
    'DanskeSpil/Domain/NumberGamePackage/Scripts/Helpers/PackagesDictionary',
    'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/GenericOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/Shared/SkeletonCardLoader',
    'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/PurchaseFlow/PurchaseFlow',
    'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/CustomPackage/CustomPackageFlow'
  ],
  function (Component, AppEvent, Params, ConfirmHelper, PopoverDrawer, PackagesDictionary, JackpotInfo, GenericOverlay, Overlay, SkeletonCardLoader, PurchaseFlow, CustomPackageFlow) {
    // Component:
    Component('custom-week-package', [PackagesDictionary, JackpotInfo], function (m, route, settings, property, $element) {
    // Components:
      var root = {
        controller: function () {
          this.settings = settings;
          this.property = property;
          this.$element = $element;
          this.route = route;
          this.dictionary = PackagesDictionary.get;
          this.standardPackageDictionary = this.dictionary.bind({ prefix: 'MultiPackages/StandardPackageItem/' });
          this.customPackageDictionary = this.dictionary.bind({ prefix: 'MultiPackages/CustomPackage/' });
          this.customPackageGameOptions = this.settings.customPackageGameOptions;
          this.mainPopoverDrawer = new PopoverDrawer();
          this.errorLogged = m.prop(null);
          this.loading = m.prop(false);

          this.gameTypes = {
            AllOrNothing: 'allornothing',
            LottoWednesday: 'lotto',
            LottoSaturday: 'lotto',
            VikingLotto: 'vikinglotto',
            EuroJackpot: 'eurojackpot',
            Keno: 'keno',
            Joker: 'joker',
            JokerSaturday: 'joker',
            JokerWednesday: 'joker'
          };
          this.gameLogos = {
            allornothing: '/Components/DanskeSpil/Domain/NumberGamePackage/Graphics/SpriteSheets/PackageCommonIcons/allornothing_logo.svg',
            eurojackpot: '/Components/DanskeSpil/Domain/NumberGamePackage/Graphics/SpriteSheets/PackageCommonIcons/icon-eurojackpot_logo_bg_yellow.svg',
            lotto: '/Components/DanskeSpil/Domain/NumberGamePackage/Graphics/SpriteSheets/PackageCommonIcons/icon-lotto_logo_red.svg',
            vikinglotto: '/Components/DanskeSpil/Domain/NumberGamePackage/Graphics/SpriteSheets/PackageCommonIcons/icon-vikinglotto_logo_blue.svg',
            keno: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes/keno.svg',
            joker: '/Components/DanskeSpil/Domain/NumberGamePackage/Graphics/SpriteSheets/PackageCommonIcons/joker_black_with_hat.svg'
          };
          this.gameBadges = {
            lotto: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameBadges/game_badge-lotto.svg',
            vikinglotto: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameBadges/game_badge-vikinglotto.svg',
            eurojackpot: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameBadges/game_badge-eurojackpot.svg',
            keno: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameBadges/game_badge-keno.svg'
          };

          this.confirmErrorOverlay = (errorType) => {
            const error = ConfirmHelper.getPurchaseError(errorType);
            if (this.errorLogged() === error) return;
            this.errorLogged(error);
            const texts = {
              header: this.dictionary('PurchaseErrors/' + error + 'Header'),
              body: this.dictionary('PurchaseErrors/' + error + 'Body'),
              dismiss: this.dictionary('PurchaseErrors/' + error + 'Dismiss')
            };
            const errorOverlay = new Overlay(GenericOverlay(texts));
            errorOverlay.show(() => this.errorLogged(null), () => this.errorLogged(null));
          };

          this.initPurchaseFlowPopover = () => {
            this.mainPopoverDrawer.setContent(m(PurchaseFlow, { controller: this }));
            this.mainPopoverDrawer.onClose(() => {
              Params.set('packageFlowStep=packageSelection');
              const packageModelStatus = this.customPackageFlowController.customPackageModel().status();
              if (packageModelStatus !== 'open') {
                this.loading(true);
                Params.remove('packageInstanceId');
                setTimeout(() => {
                  this.customPackageFlowController.getOrGeneratePackageModel();
                  this.loading(false);
                  m.redraw();
                }, 0);
              }
              if (packageModelStatus === 'completed') {
                AppEvent.fire('receipt_closed');
              }
            });

            if (!this.mainPopoverDrawer.isShown()) {
              this.$element.scrollIntoView({ behavior: 'smooth' }); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
              this.mainPopoverDrawer.show();
              setTimeout(() => {
                m.redraw();
              }, 0);
            }
          };

          this.customPackageFlowController = new CustomPackageFlow.controller({ controller: this });
        },
        view: function (controller) {
          const flowStep = Params.extract('packageFlowStep');
          if (flowStep === 'purchaseFlow') {
            controller.initPurchaseFlowPopover();
          }

          const content = () => {
            return m('.multi-packages-section__inner', [
              controller.loading() ? m(SkeletonCardLoader, { controller }) : m(CustomPackageFlow, { controller: controller.customPackageFlowController })
            ]);
          };
          return m('div', [
            content()
          ]);
        }
      };

      // Setup routes:
      route('/', root);
    });
  });

defineDs('DanskeSpil/Domain/Lotto/Scripts/Components/LottoReceipt',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Countdown',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/CouponOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoDictionary',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoUtils',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
    'DanskeSpil/Domain/Lotto/Scripts/Models/LottoGame',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/AccountIntegration',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/SaturdayLottoApi',
    'Shared/Framework/Ensighten/Scripts/Ensighten'
  ],
  function (Component, Countdown, CouponOverlay, Overlay, LottoDictionary, LottoUtils, LottoInfo, LottoGame, AccountIntegration, Api, Ensighten) {

    // Component:
    Component('lotto-receipt', [AccountIntegration, LottoDictionary, LottoInfo], function (m, route, settings) {

      // Variables:
      var d = LottoDictionary.get;
      var id = LottoUtils.getQueryParam('gameInstanceId');

      // Missing id:
      if (!id) {
        return;
      }

      var game = LottoGame.get(id);

      // Missing game:
      if (!game) {
        console.error('LottoReceipt: Game not found, id: ' + id);
        return;
      }

      var feedData = LottoInfo.saturday().data();

      // Components:
      var root = {
        controller: function () {

          // This controller:
          var controller = this;

          this.api = function () {
            return Api;
          }.bind(this);

          // Variables:
          this.game = m.prop(game);
          this.options = m.prop(settings || []);
          this.showSubscribeReminder = m.prop(false);
          this.alreadySubscribed = m.prop(false);
          this.subscribed = m.prop(false);
          this.loadingReminderAlert = m.prop(null);

          // Remove the personalized coupon once the purchase is complete
          if (this.game().playType() !== 'Lightning') {
            this.api().clearPersonalizedCoupon(this.game().playType());
          }

          // Functions:
          this.toggleReminderAlert = function () {
            var success = function () {
              if (this.subscribed()) {
                this.loadingReminderAlert('success-remind-me');
                Ensighten.pushGaEvent('permission', 'checkBoxActivated', 'multiWeekReminder', null, false);
              } else {
                this.loadingReminderAlert('success-dont-remind-me');
                Ensighten.pushGaEvent('permission', 'checkBoxDeactivated', 'multiWeekReminder', null, false);
              }
              m.redraw();
            }.bind(this);

            var fail = function (err) {
              if (err.errorMessage === 'NUMBERGAMES.USER_UNAUTHORIZED') {
                this.loadingReminderAlert('error-user-unauthorized');
              } else {
                this.loadingReminderAlert('error');
              }
              m.redraw();
            }.bind(this);

            this.subscribed(!this.subscribed());
            this.loadingReminderAlert('loading');
            this.api().setSubscribeReminder(this.subscribed()).then(success, fail);
            m.redraw();
          }.bind(this);

          this.gotoCoupon = (couponId) => {
            const couponOverlay = new Overlay(CouponOverlay('lotto-coupon-overlay', couponId, {
              printButton: d('LottoReceiptStep/CouponOverlay/printButton'),
              closeButton: d('LottoReceiptStep/CouponOverlay/closeButton')
            }));
            couponOverlay.show();
            return false;
          };

          var playType = d('PlayTypes/Lotto' + this.game().playType(), this.game().playType() == 'System' ? { level: this.game().systemName() } : {});
          playType = playType.toLowerCase().replace(/^[\u00C0-\u1FFF\u2C00-\uD7FF\w]|\s[\u00C0-\u1FFF\u2C00-\uD7FF\w]/g, function (letter) {
            return letter.toUpperCase();
          });
          var totalPrice = this.game().totalPrice();
          var rows = this.game().getRowsSummary().length;
          var draws = this.game().numberOfDraws();
          var joker = this.game().withJokerSaturday() || this.game().withJokerWednesday();
          var jokerStake = this.game().stakePerJoker() * this.game().getJokerCount();
          var jokerCount = this.game().getJokerCount();
          var firstName;
          var lastName;
          var userName;

          AccountIntegration.getUserData().then(function (userData) {
            firstName = userData.firstName;
            lastName = userData.lastName;
            userName = userData.userName;
          });

          this.heading = d('LottoReceiptStep/Heading', {
            rows: rows,
            playType: playType,
            totalPrice: totalPrice,
            draws: draws,
            joker: joker ? 'med Joker' : 'uden Joker',
            jokerStake: jokerStake,
            jokerCount: jokerCount,
            firstName: firstName,
            lastName: lastName,
            userName: userName
          });

          var draw = feedData.openDraw;
          var drawDate = draw ? LottoUtils.parseISO8601(draw.closingTime).dateObject : null;
          this.countdownOptions = {
            countdownTime: drawDate ? drawDate.getTime() / 1000 : 0,
            dictionary: d,
            disabled: settings.isCountdownDisabled,
            noOpenDraw: false
          };


          // Update balance in UI
          AccountIntegration.triggerBalanceUpdate();

          if (this.game().status() == 'completed') {
            // Ecommerce tracking
            this.game().trackPurchase();
          }

          // If user logs out - redirect to Lotto frontpage
          window.DSAPI && DSAPI.ready(function () {
            DSAPI.Account.subscribeUserLoggedOut({
              callback: function () {
                location.href = controller.game().startUrl();
              }
            });
          });

          if (this.game().numberOfDraws() > 1) {
            var success = function (data) {
              this.showSubscribeReminder(data.showSubscribeReminder);
              var subscribed = data.alreadySubscribed;
              this.alreadySubscribed(subscribed);
              this.subscribed(subscribed);
              m.redraw();
            }.bind(this);

            var fail = function () {
              // we fail silently by not showing the option to subscribe if the feed fails
            };

            this.api().getSubscribeReminder().then(success, fail);
          }
        },

        view: function (controller) {
          return m('.lotto-receipt', [
            m('.countdown-wrapper', [
              m('.lotto-section', [
                m('.lotto-content-wrapper', [
                  m('.receipt-confirmation', [
                    settings.SelectedIconString != '' ?
                      m('svg', { class: 'receipt-icon' }, [
                        m('use', {
                          class: 'checkmark-in-circle',
                          href: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/SitecoreIcons.svg#' + (settings.SelectedIconString != '' ? settings.SelectedIconString : '')
                        })
                      ])
                      : '',
                    m('p.receipt-text', controller.heading),
                    m('p.receipt-text', [
                      m('a.go-to-coupon', { onclick: () => controller.gotoCoupon(controller.game().couponId()) }, d('LottoReceiptStep/GotoCoupon'))
                    ]),

                    controller.game().extraCouponId() ? [
                      m('p.receipt-text', d('LottoReceiptStep/ExtraCouponHeading')),
                      m('p.receipt-text', [
                        m('a.go-to-coupon', { onclick: () => controller.gotoCoupon(controller.game().extraCouponId()) }, d('LottoReceiptStep/GotoExtraCoupon'))
                      ]),
                    ] : null,

                    controller.showSubscribeReminder() ? [
                      m('.reminder-alert', {
                        class: controller.loadingReminderAlert() === 'loading' ? 'reminder-alert--loading' : ''
                      }, [
                        m('input', {
                          type: 'checkbox',
                          id: 'reminderCheckbox',
                          checked: (controller.subscribed() ? 'checked' : ''),
                          onchange: m.withAttr('checked', controller.toggleReminderAlert)
                        }),
                        m('label', {
                          for: 'reminderCheckbox'
                        }, [
                          m('.checkbox'),
                          controller.alreadySubscribed() ?
                            d('LottoReceiptStep/SubscribeReminder/KeepRemindMe') :
                            d('LottoReceiptStep/SubscribeReminder/RemindMe')
                        ]),
                        controller.loadingReminderAlert() === 'error-user-unauthorized' ? m('p', d('LottoReceiptStep/SubscribeReminder/ErrorUserUnauthorized')) : '',
                        controller.loadingReminderAlert() === 'error' ? m('p', d('LottoReceiptStep/SubscribeReminder/Error')) : '',
                        controller.loadingReminderAlert() === 'success-remind-me' ? m('p', d('LottoReceiptStep/SubscribeReminder/SuccessRemindMe')) : '',
                        controller.loadingReminderAlert() === 'success-dont-remind-me' ? m('p', d('LottoReceiptStep/SubscribeReminder/SuccessDontRemindMe')) : '',
                      ])
                    ] : '',

                  ]),
                  m('hr', { class: 'hr' }),
                  Countdown(controller.countdownOptions)
                ])
              ])
            ])
          ]);
        }
      };

      // Setup Routes:
      route('/', root);

    });

  });

defineDs('DanskeSpil/Domain/Lotto/Scripts/Campaigns/Components/SnowLayer',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/Lotto/Scripts/Campaigns/Templates/Christmas/SnowLayer'
  ],
  function (Component, SnowLayer) {
    // Component:
    Component('snow-layer', function (ignoreM, route) {
      var root = {
        controller: function () {},
        view: function () {
          return SnowLayer();
        }
      };
      // Routes:
      route('/', root);
    });
  });
defineDs('DanskeSpil/Domain/Lotto/Scripts/Campaigns/Components/CampaignPermisionForm',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoDictionary',
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
    'DanskeSpil/Domain/Lotto/Scripts/Campaigns/Templates/Christmas/SnowLayer'
  ],
  function (Component, LottoDictionary, ApiRequest, SnowLayer) {

    // Component:
    Component('campaign-permission-form', [LottoDictionary], function (m, route, settings) {

      // Variables:

      // Components:
      var root = {
        controller: function () {
          this.d = LottoDictionary.get;
          this.loading = m.prop(false);
          this.showSuccessMessage = m.prop(false);

          this.onSubmit = function (e) {
            e.preventDefault();
            this.loading(true);
            var $form = e.target;
            var $inputs = $form.querySelectorAll('input'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

            var formData = {};
            $inputs.forEach(function ($input) { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
              formData[$input.getAttribute('name')] = $input.value; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            });

            var resetForm = function () {
              $inputs.forEach(function ($input) { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
                if ($input.getAttribute('type') === 'checkbox') { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
                  $input.checked = false;
                  return;
                }
                $input.value = '';
              });
            };

            ApiRequest({
              url: '/dlo/scapi/permission/campaign',
              method: 'POST',
              data: formData
            }).then(function () {
              this.loading(false);
              this.showSuccessMessage(true);
              resetForm();
              m.redraw();
            }.bind(this)).catch(function () {
              this.loading(false);
              m.redraw();
              console.warn('Something went wrong!');
            }.bind(this));
          }.bind(this);
        },
        view: function (controller) {
          return [
            m('.campaign-permission-form__page', [
              SnowLayer(!controller.showSuccessMessage()),
              m('.campaign-permission-form__page-inner', [
                settings.headerImageSvg?.length > 0 ?
                  m('div.campaign-permission-form__page-image', m.trust(settings.headerImageSvg)) :
                  settings.headerImageUrl?.length > 0 ? m('img', { src: settings.headerImageUrl }) : '',
                m('h2.campaign-permission-form__page-title', settings.header),
                m('p.campaign-permission-form__page-text', settings.subHeader),
                !controller.showSuccessMessage() ?
                  m('form.campaign-permission-form__form-wrapper', { onsubmit: controller.onSubmit }, [
                    m('.campaign-permission-form__inputs-wrapper', [
                      m('.campaign-permission-form__input-wrapper', [
                        m('input.campaign-permission-form__input', {
                          type: 'text',
                          placeholder: ' ',
                          required: true,
                          name: 'firstName',
                          id: 'campaign-permission-form__first-name'
                        }),
                        m('label.campaign-permission-form__label required', { for: 'campaign-permission-form__first-name' }, settings.firstName)
                      ]),
                      m('.campaign-permission-form__input-wrapper', [
                        m('input.campaign-permission-form__input', {
                          type: 'text',
                          placeholder: ' ',
                          required: true,
                          name: 'lastName',
                          id: 'campaign-permission-form__last-name'
                        }),
                        m('label.campaign-permission-form__label required', { for: 'campaign-permission-form__last-name' }, settings.lastName)
                      ]),
                      m('.campaign-permission-form__input-wrapper', [
                        m('input.campaign-permission-form__input', {
                          type: 'email',
                          placeholder: ' ',
                          required: true,
                          name: 'email',
                          id: 'campaign-permission-form__email'
                        }),
                        m('label.campaign-permission-form__label required', { for: 'campaign-permission-form__email' }, settings.email)
                      ]),
                      m('.campaign-permission-form__input-wrapper', [
                        m('input.campaign-permission-form__input', {
                          type: 'checkbox',
                          required: true,
                          name: 'emailPermission',
                          value: 'true',
                          id: 'campaign-permission-form__emailPermission'
                        }),
                        m('label.campaign-permission-form__checkbox-label', { for: 'campaign-permission-form__emailPermission' }, m.trust(settings.terms))
                      ])
                    ]),
                    m('button.campaign-permission-form__submit-button', {
                      type: 'submit',
                      class: controller.loading() ? ' loading' : ''
                    }, [
                      settings.submitButton,
                      m('span.loader', [
                        m('span.loader__dot'),
                        m('span.loader__dot'),
                        m('span.loader__dot')
                      ])
                    ])
                  ]) : m('.campaign-permission-form__success-message', m.trust(settings.successMessage))
              ])
            ])
          ];
        }
      };

      // Routes:
      route('/', root);

    });

  });

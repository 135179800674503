defineDs('DanskeSpil/Framework/AppUniverse/Scripts/AppUniverse/Settings/SliderSettings',
  function () {
    var phone = function () {
      var breakpoints = {
        small: 470,
        medium: 690,
        large: 930
      };

      return {
        arrows: false,
        dots: false,
        infinite: false,
        speed: 250,
        slidesToShow: 4,
        draggable: true,
        variableWidth: true,
        mobileFirst: true,
        focusOnSelect: true,
        touchThreshold: 10,
        responsive: [{
          breakpoint: 0,
          settings: {
            slidesToShow: 1,
          }
        }, {
          breakpoint: breakpoints.small,
          settings: {
            slidesToShow: 2,
          }
        }, {
          breakpoint: breakpoints.medium,
          settings: {
            slidesToShow: 3,
          }
        }, {
          breakpoint: breakpoints.large,
          settings: {
            slidesToShow: 4
          }
        }]
      };
    };

    var tablet = function () {
      var breakpoints = {
        small: 830,
        medium: 1380
      };

      return {
        arrows: false,
        dots: false,
        infinite: false,
        speed: 250,
        slidesToShow: 3,
        draggable: true,
        variableWidth: true,
        mobileFirst: true,
        focusOnSelect: true,
        touchThreshold: 10,
        responsive: [{
          breakpoint: 0,
          settings: {
            slidesToShow: 1,
          }
        }, {
          breakpoint: breakpoints.small,
          settings: {
            slidesToShow: 2,
          }
        }, {
          breakpoint: breakpoints.medium,
          settings: {
            slidesToShow: 3,
          }
        }]
      };
    };

    var instructionSteps = function () {
      var breakpoints = {
        small: 650,
        medium: 980,
        large: 1510
      };

      return {
        arrows: true,
        dots: false,
        infinite: false,
        speed: 250,
        slidesToShow: 4,
        draggable: true,
        variableWidth: true,
        mobileFirst: true,
        focusOnSelect: false,
        touchThreshold: 10,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        responsive: [{
          breakpoint: 0,
          settings: {
            slidesToShow: 1,
          }
        }, {
          breakpoint: breakpoints.small,
          settings: {
            slidesToShow: 2,
          }
        }, {
          breakpoint: breakpoints.medium,
          settings: {
            slidesToShow: 3,
          }
        }, {
          breakpoint: breakpoints.large,
          settings: {
            slidesToShow: 4
          }
        }]
      };
    };

    // Public functions:
    return {
      phone: phone,
      tablet: tablet,
      instructionSteps: instructionSteps
    };

  });

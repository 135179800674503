defineDs('DanskeSpil/Domain/Lotto/Scripts/Campaigns/Components/CampaignInformOverlay',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'Common/Framework/EventHandling/Scripts/Event',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoDictionary',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/GenericOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
    'Shared/Framework/Ensighten/Scripts/Ensighten'
  ],
  function (Component, Event, Storage, LottoDictionary, Overlay, GenericOverlay, Utils, Ensighten) {
    // Component:
    Component('campaign-lotto-inform-overlay', [LottoDictionary], function (m, route, settings) {
      if (Utils.getQueryParam('klubId') && Utils.getQueryParam('gameType')) {
        // if we have klubId and gameType then we know we are in spil-sammen
        if (Utils.getQueryParam('gameType') !== 'lotto') return;
      }

      // Check if overlay was shown:
      var identifier = settings.identifier;
      var wasShown = Storage.get(identifier);
      if (settings.campaign !== 'SuperMillionaire') {
        if (wasShown) return;
      }

      var confirmAction = function () {
        Ensighten.pushGaEvent('onboarding_popup', 'cta_click', settings.confirm);
        Storage.set(identifier, true);
      };

      const onClose = () => {
        Ensighten.pushGaEvent('onboarding_popup', 'closed', '');
      };

      // Components:
      var init = function () {
        var root = {
          controller: function () {
            var className = null;
            var svgUsePath = null;
            let iconUrl = null;
            if (settings.campaign.indexOf('Sommerlotto') !== -1) {
              className = 'summer-campaign';
              svgUsePath = '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/SummerLotto.svg#printer_ticket';
            }
            if (settings.campaign.indexOf('JuleLotto2022') > -1) {
              className = 'christmas-2022-campaign';
              svgUsePath = null;
              iconUrl = '/Components/DanskeSpil/Domain/Lotto/Graphics/Campaigns/Christmas/x-mas_banana_wheel.svg';
            }
            if (settings.campaign === 'SuperMillionaire') {
              className = 'super-millionaire';
            }
            if (settings.image) {
              iconUrl = settings.image;
            }
            var genericOverlayDictionary = {
              className: className,
              svgUsePath: svgUsePath,
              iconUrl: iconUrl,
              imageCaption: settings.imageCaption,
              header: settings.header,
              body: settings.body,
              dismiss: settings.confirm
            };

            Ensighten.pushGaEvent('onboarding_popup', 'shown', '');

            new Overlay(GenericOverlay(genericOverlayDictionary)).show(confirmAction, onClose);
          }
        };

        // Routes:
        route('/', root);
      };

      // check if the overlay is used in plus basket and if the basket contains any lotto games
      // we need to wait for all the helpers for all games to be ready before using the model
      if (settings.showType === 'PLUS') {
        requireDs([
          'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
          'DanskeSpil/Domain/AllOrNothing/Scripts/Helpers/AllOrNothingInfo',
          'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo',
          'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
          'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',
          'DanskeSpil/Domain/Subscriptions/Scripts/Helpers/ModelStorage'
        ], function (VikingLottoInfo, AllOrNothingInfo, EurojackpotInfo, LottoInfo, KenoInfo, ModelStorage) {
          m.sync([LottoInfo.ready, VikingLottoInfo.ready, EurojackpotInfo.ready, AllOrNothingInfo.ready, KenoInfo.ready]).then(function () {
            const checkPlusBasket = () => {
              var basket = ModelStorage.basket('plus');
              const hasLottoGame = basket?.lotto.length > 0;
              const hasPackageWithLotto = basket?.wizardpackage?.some((packageItem) => {
                return packageItem.gameInstances?.()?.lotto?.length > 0;
              });

              if (hasLottoGame || hasPackageWithLotto) {
                init();
              }
            };

            checkPlusBasket();

            Event.subscribe('setupSubscription', checkPlusBasket);
          });
        });
      } else {
        init();
      }
    });
  });

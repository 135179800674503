defineDs('DanskeSpil/Domain/Lotto/Scripts/Helpers/FeaturedWinnersApi',
  [
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils'
  ],
  function (ApiRequest, Utils) {

    // Variables:
    var baseURL = DS.Config.CONTEXTPREFIX + '/scapi/numbergames/winners/get';

    // Functions:
    var prepareParamString = Utils.prepareParamString;

    var getFeaturedWinners = function (options) {
      var params = prepareParamString(options);

      return ApiRequest({
        url: baseURL + params,
        localCacheTTL: 60,
        unwrapSuccess: function (response) {
          return response;
        }
      });
    };

    // Public functions:
    return {
      getFeaturedWinners: getFeaturedWinners
    };

  });

defineDs('DanskeSpil/Domain/Lotto/Scripts/Components/LottoConfirm',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'Common/Framework/EventHandling/Scripts/AppEvent',
    'Common/Framework/EventHandling/Scripts/Event',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/AccountIntegration',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Confirm',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/ErrorOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/Shared/PopoverDrawer',
    'DanskeSpil/Domain/NumberGames/Scripts/Helpers/CampaignEngineApi',
    'DanskeSpil/Domain/NumberGames/Scripts/Helpers/CouponHelpers',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoDictionary',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoUtils',
    'DanskeSpil/Domain/Lotto/Scripts/Models/LottoGame',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/ConfirmBar',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/LottoClassic/DeleteOverlay',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/ConfirmOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Resize',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/ReceiveWinnerMessage',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/DataLayer',
    'Shared/Framework/Ensighten/Scripts/Ensighten',
    'DanskeSpil/Domain/Lotto/Scripts/Campaigns/Templates/Christmas/ExtraDrawUpsell',
  ],
  function (Component, AppEvent, Event, AccountIntegration, Confirm, ErrorOverlay, Overlay, PopoverDrawer, CampaignEngineApi, CouponHelpers, LottoDictionary, LottoInfo, LottoUtils, LottoGame, ConfirmBar, DeleteOverlay, ConfirmOverlay, Resize, ReceiveWinnerMessage, DataLayer, Ensighten, ExtraDrawUpsell) {

    // Component:
    Component('lotto-confirm', [AccountIntegration, LottoDictionary, LottoInfo], function (m, route, settings, property) {

      // Variables:
      var id = LottoUtils.getQueryParam('gameInstanceId');
      var rebuyCouponId = LottoUtils.getQueryParam('rebuyCouponId');
      var game;

      // Missing id:
      if (!id && !rebuyCouponId) {
        return;
      }

      if (rebuyCouponId && settings.returnToCompletedGamesOverviewLink) {
        var page = LottoUtils.getQueryParam('page') || 1;
        var period = LottoUtils.getQueryParam('period') || 365;
        var offset = LottoUtils.getQueryParam('offset') || 1;
        var type = LottoUtils.getQueryParam('type') || 'afsluttede';
        var channel = LottoUtils.getQueryParam('channel') || 'alle-kanaler';
        game = LottoGame.new({
          rebuyCouponId: rebuyCouponId,
          startUrl: settings.returnToCompletedGamesOverviewLink + '#/' + page + '/' + period + '/' + offset + '/' + type + '/' + channel
        });
      } else {
        game = LottoGame.get(id);
      }

      // Missing game:
      if (!game) {
        console.error('LottoConfirm: Game not found, id: ' + id);

        return;
      }

      // Components:
      var root = {
        controller: function () {

          // Variables:
          var infoFeed = LottoInfo.saturday();
          var self = this;

          this.askForDeposit = m.prop(false);
          this.d = LottoDictionary.get;
          this.game = m.prop(game);
          this.loading = m.prop(false);
          this.gradientHeight = m.prop();
          this.property = property;
          this.openDraw = m.prop(infoFeed.data().openDraw || null);
          this.rows = m.prop();
          this.rowHeight = m.prop();
          this.rowLimit = m.prop(2);
          this.notifyPrizeEmail = m.prop();
          this.notifyPrizeSms = m.prop();
          this.showPrizeNotificationSection = m.prop(true);
          this.ready = m.prop(false);
          this.rebuyCouponNotFound = m.prop(false);
          this.redirectWhenNoOpenDrawLink = m.prop(settings.redirectWhenNoOpenDrawLink);
          this.redirectWhenNoOpenDrawLinkActivate = m.prop(settings.redirectWhenNoOpenDrawLinkActivate);

          this.isOpenDrawPartOfCampaign = m.prop(false);
          this.gameContainsExtraDraw = m.prop(false);
          this.isActiveCampaignChristmas = m.prop(false);

          // Functions:
          this.confirmErrorOverlay = function (errorTexts) {
            return new Overlay(ErrorOverlay('lotto-classic-game-overlay', errorTexts));
          }.bind(this);

          this.deleteOverlay = function () {
            return new Overlay(DeleteOverlay(this.d));
          }.bind(this);

          this.drawDate = function () {
            var s = this.d('ConfirmStep/DrawDateTo');

            return m.trust(this.game().drawDateHtml(s));
          }.bind(this);

          this.back = function () {
            AppEvent.fire('purchase-cancelled');
            location.href = this.game().cancelUrl();
          }.bind(this);

          this.confirm = function () {
            if (!this.loading()) {
              this.loading(true);

              this.game().purchase({
                drawId: (self.openDraw() && self.openDraw().id) || null,
                notifyPrizeEmail: self.notifyPrizeEmail(),
                notifyPrizeSms: self.notifyPrizeSms()
              }).then(function (response) {
                self.loading(false);

                m.redraw();

                var receiptUrl = self.game().receiptUrl();

                try {
                  window.history.replaceState({}, self.d('ConfirmStep/ReceiptTitle'), receiptUrl);
                } catch (error) {
                  // Nothing to handle here, just proceed to location.href
                }

                AppEvent.fire('purchase-complete', { couponData: response });
                location.href = receiptUrl;
              }, function (data) {
                self.loading(false);

                m.redraw();

                Confirm.handlePurchaseError(self.game, (data && data.errorMessage) || null, self.showError);
                AppEvent.fire('purchase-failed');
              });
            }

            m.redraw(); // for applying "pending" state in view

            return false;
          }.bind(this);

          // Deposit flow:
          this.openDeposit = function () {
            AccountIntegration.openDeposit(this.game().totalPrice(), function () {
              location.href = this.game().cancelUrl();
            });
          }.bind(this);

          // Delete row from game, and in view
          this.deleteRow = function (rowNumber) {
            var allRows = this.game().getRows(false);
            var index = 0;
            var rows = this.game().getRowsSummary();
            var selectedNumbers = rows[rowNumber - 1];

            for (var i = 0; i < allRows.length; i++) {
              var row = allRows[i];
              var numbers = row.numbers.map(function (numbers) {
                return numbers.number;
              });

              var match = numbers.length !== 0;

              for (var j = 0; j < numbers.length; j++) {
                if (numbers[j] !== selectedNumbers[j]) {
                  match = false;

                  break;
                }
              }

              if (match) {
                index = i;

                break;
              }
            }

            rowNumber = index + 1;

            this.property('animate', 'remove');

            if (typeof this.deleteOverlay !== 'undefined') {
              this.property('deleteRow', rowNumber);

              var self = this;

              this.deleteOverlay().show(function () {
                game.removeRow(rowNumber);

                if (!game.isGameValid() && self.back) {
                  game.addRow();

                  game.save();

                  self.back();
                }
              });
            }
          }.bind(this);

          // Show all rows:
          this.showAllRows = function () {
            this.rowLimit(this.rows().length + 1);
          }.bind(this);

          // Is there any more rows in the current game:
          this.maxRowsReached = function () {
            return (this.rowLimit() > 2 || this.rows().length <= 2);
          }.bind(this);

          this.getContainerStyle = function () {
            if (!this.rowHeight() || this.rows().length <= 2) {
              return 'max-height: auto';
            }

            var height = this.rowHeight() * Math.min(this.rowLimit() - 1, this.rows().length);

            if (!this.maxRowsReached()) {
              height += this.gradientHeight();
            }

            return 'max-height: ' + height + 'px';
          }.bind(this);

          this.showError = function (errorType) {
            console.debug('errorType', errorType);

            var error = Confirm.getPurchaseError(errorType);

            if (this.redirectWhenNoOpenDrawLinkActivate() && errorType === 'NUMBERGAMES.NO_OPEN_DRAW') {
              window.location.href = this.redirectWhenNoOpenDrawLink();
            } else {
              var texts = {
                header: this.d('PurchaseErrors/' + error + 'Header'),
                body: this.d('PurchaseErrors/' + error + 'Body'),
                dismiss: this.d('PurchaseErrors/' + error + 'Dismiss')
              };

              this.confirmErrorOverlay(texts).show();
            }
          }.bind(this);

          // Game status class:
          this.gameStatusClass = () => {
            return `status-${this.game().status()}`;
          };

          // Context:
          this.game().ready().promise.then(function () {
            Confirm.checkAuthAndBalance(self.game).then(function () {
              AccountIntegration.getUserData().then(function (customerInfo) {
                self.notifyPrizeEmail(customerInfo.notifyPrizeEMail || false);
                self.notifyPrizeSms(customerInfo.notifyPrizeSms || false);

                // Show if notification section if not already notified on either e-mail or SMS:
                self.showPrizeNotificationSection(!self.notifyPrizeEmail() && !self.notifyPrizeSms());

                self.rows(self.game().getRowsSummary());

                self.ready(true);

                m.redraw();
              });
            }, function (error) {
              if (error === 'ASK_FOR_DEPOSIT') {
                self.askForDeposit(true);
                AppEvent.fire('ask-for-deposit', { totalPrice: self.game().totalPrice() });

                // Add ensighten virtualPage event:
                if (self.game) {
                  Ensighten.pushVirtualPage('tank_op', DataLayer.categoryName(self.game().playType()) + '/tank_op', 'notEnoughCreditPage');
                }

                self.ready(true);

                m.redraw();
              }
            });
          }, function () {
            self.rebuyCouponNotFound(true);

            self.ready(true);

            m.redraw();
          }.bind(this));

          if (this.game().playType() !== null && this.game().playType() !== 'Lightning') {
            this.game().api().createPersonalizedCoupon(this.game().playType(), this.game().toJSON());
          }

          // Push addToCart event to dataLayer:
          this.game().ready().promise.then(function () {
            game.trackingAddToCart();
          });

          this.toggleCollapsible = function (ev) {
            ev.currentTarget.parentElement.classList.toggle('confirm-page__collapsible--show');
          };

          this.game().ready().promise.then(() => {
            if (this.game().originalExtraDraw()) {
              CouponHelpers.findCampaignFromCampaignEngine(this.game().originalExtraDraw().campaignNumbers).then((campaignDataFromCampaignEngine) => {
                if (campaignDataFromCampaignEngine) {
                  this.game().originalExtraDraw().campaignData.forEach((campaign) => {
                    if (campaignDataFromCampaignEngine.campaignNumbers.includes(campaign.id)) {
                      campaign.campaignTag = campaignDataFromCampaignEngine.campaignTag;
                    }
                  });

                  if (!this.game().rowPriceChanged() &&
                      campaignDataFromCampaignEngine.campaignTag?.toLowerCase() === 'christmas' && this.isActiveCampaignChristmas()) {
                    this.game().totalPrice(this.game().originalPrice());
                    this.game().numberOfDraws(this.game().originalNumberOfDraws());
                    this.game().reducedWeeks(false);
                    this.game().save();
                  }
                }
              });
            }
            this.gameContainsExtraDraw(this.game().rawDrawsDataFromInfoFeed?.().some((draw) => draw.drawType?.toLowerCase() === 'extra'));
            if ((game.rowPriceChanged() || game.reducedWeeks()) &&
                !game.originalExtraDraw()) {
              var bodyText = this.d('ConfirmPage/PriceChange/Notice');

              if (game.reducedWeeks()) {
                bodyText = this.d('ConfirmPage/PriceChange/NoticeReducedWeeks', { ORIGINALDRAWS: game.originalNumberOfDraws(), DRAWS: game.numberOfDraws() });
              }

              new Overlay(ConfirmOverlay('lotto-classic-game-overlay', {
                header: this.d('ConfirmPage/PriceChange/OverlayHeader'),
                body: bodyText,
                confirm: this.d('ConfirmPage/PriceChange/OverlayButton')
              })).show();
            }
          });

          this.checkingForActiveCampaign = m.prop(false);
          this.hasActiveCampaign = m.prop(false);
          this.activeCampaignData = m.prop(null);
          this.activeCampaignModel = m.prop(null);
          const checkBalanceAfterExtraDrawAdjustment = () => {
            if (this.loading()) return;
            Confirm.checkAuthAndBalance(this.game).catch((error) => {
              if (error === 'ASK_FOR_DEPOSIT') {
                this.askForDeposit(true);
                AppEvent.fire('ask-for-deposit', { totalPrice: this.game().totalPrice() });

                // Add ensighten virtualPage event:
                if (this.game) {
                  Ensighten.pushVirtualPage('tank_op', DataLayer.categoryName(this.game().playType()) + '/tank_op', 'notEnoughCreditPage');
                }

                m.redraw();
              }
            });
          };
          const showFAQOverlay = () => {
            Event.fire('ds.event.accordionInfoOverlay:show', {
              key: 'productchange',
              className: 'accordion-info-overlay__christmas-product-change'
            });
          };
          const toggleExtraDraw = (state) => {
            if (!this.gameContainsExtraDraw() || this.game().originalExtraDraw()) return;

            this.game().originalNumberOfDraws(this.game().numberOfDraws());
            this.game().originalPrice(this.game().totalPrice());
            this.game().numberOfDraws(this.game().numberOfDraws() + (state ? 1 : -1));
            this.game().reducedWeeks(true);
            this.game().rowPriceChanged(true);
            this.game().save();

            checkBalanceAfterExtraDrawAdjustment();

            Event.fire('ds.event.accordionInfoOverlay:show', {
              key: 'julelotto2024-rebuy',
              onConfirm: showFAQOverlay
            });
            Ensighten.pushGaEvent('julelotto', 'popup_shown', 'lotto', 'julelotto2024-rebuy');
          };
          CampaignEngineApi.getActiveCampaignForGame('lotto')
            .then((response) => {
              this.hasActiveCampaign(true);
              this.checkingForActiveCampaign(false);
              this.activeCampaignData(response);
              this.activeCampaignModel({
                name: response.title,
                texts: response.texts,
                graphics: response.graphics,
                primaryColor: response.primaryColor,
                secondaryColor: response.secondaryColor,
                campaignNumber: response.activeCampaign.campaignNo,
                campaignNumbers: response.campaignNumbers,
                showOnboarding: response.activeCampaign.features.enableOnboarding,
                isThemingEnabled: response.activeCampaign.features.enablePurchaseFlowGraphics,
                campaignType: response.campaignType,
              });

              this.isActiveCampaignChristmas(response.campaignTag?.toLowerCase() === 'christmas');

              this.game().ready().promise.then(() => {
                if (this.isActiveCampaignChristmas()) {
                  this.upSellPopover = new PopoverDrawer({ className: 'christmas-upsell-popover' });
                  this.upSellPopover.setContent(m(ExtraDrawUpsell, { controller: this }));
                  this.upSellPopover.onClose(() => {
                    checkBalanceAfterExtraDrawAdjustment();
                    m.redraw();
                  });

                  this.isOpenDrawPartOfCampaign(response.campaignNumbers.some((campaignNumber) => this.openDraw().campaignNumbers?.includes(campaignNumber)));
                  if (!this.gameContainsExtraDraw() &&
                      !this.isOpenDrawPartOfCampaign()) {
                    Confirm.checkAuthAndBalance(this.game).then(() => {
                      this.upSellPopover.show();
                      m.redraw();

                      if (this.gameContainsExtraDraw()) {
                        Event.fire('ds.event.accordionInfoOverlay:show', {
                          key: 'julelotto2024',
                          onConfirm: showFAQOverlay
                        });
                        Ensighten.pushGaEvent('julelotto', 'popup_shown', 'lotto', 'julelotto2024');
                      }
                      if (this.isOpenDrawPartOfCampaign()) {
                        Event.fire('ds.event.accordionInfoOverlay:show', {
                          key: 'julelotto2024-buyin',
                          onConfirm: showFAQOverlay
                        });
                        Ensighten.pushGaEvent('julelotto', 'popup_shown', 'lotto', 'julelotto2024-buyin');
                      }
                    });
                  }
                }
                toggleExtraDraw(this.isActiveCampaignChristmas());
              });
            })
            .catch(() => {
              toggleExtraDraw(false);
              this.checkingForActiveCampaign(false);
              m.redraw(true);
            });

          this.extraCampaignsData = null;
          this.game().rawDrawsDataFromInfoFeed().forEach((draw) => {
            if (draw.gameVersionNo > 2 && draw.campaignData) {
              this.extraCampaignsData = draw.campaignData.filter((campaign) => {
                if (campaign.id === -1) return campaign;
                if (campaign.type === 'Accumulating' && campaign.name?.toLowerCase().includes('super')) return campaign;
              });
            }
          });
        },

        view: function (controller) {

          // Ready:
          if (!controller.ready()) {
            return;
          }

          // Rebuy coupon not found:
          if (controller.rebuyCouponNotFound()) {
            controller.confirm = null;
            return m('div', { class: 'lotto-confirm ' + controller.gameStatusClass() }, [
              m('div', { class: 'lotto-section confirm-bar-container' }, [
                m('div', { class: 'lotto-content-wrapper lotto-row-display' }, [
                  m('div', { class: 'lotto-confirm-page-header' }, [
                    m('h3', { class: 'confirm-headline' }, controller.d('ConfirmStep/RebuyCouponNotFoundTitle')),
                    m('p', { class: 'alt-text' }, controller.d('ConfirmStep/RebuyCouponNotFoundDescription'))
                  ])
                ]),
                ConfirmBar(controller)
              ])
            ]);
          }

          // Deposit flow:
          if (controller.askForDeposit()) {
            return m('div', { class: 'lotto-confirm ' + controller.gameStatusClass() }, [
              m('div', { class: 'lotto-section confirm-bar-container' }, [
                m('div', { class: 'lotto-content-wrapper lotto-row-display' }, [
                  m('div', { class: 'lotto-confirm-page-header' }, [
                    m('h3', { class: 'confirm-headline' }, controller.d('ConfirmStep/DepositHeader')),
                    m('p', { class: 'alt-text' }, controller.d('ConfirmStep/DepositText')),
                    m('a', { class: 'btn cta cta-deposit', onclick: controller.openDeposit }, controller.d('ConfirmStep/DepositButton'))
                  ])
                ])
              ])
            ]);
          }

          // Variables:
          var rows = game.getRowsSummary();
          var rowsJokerSaturday = game.getJokerRowsSummary('Saturday');
          var rowsJokerWednesday = game.getJokerRowsSummary('Wednesday');
          var gameDrawDates = game.drawDates();
          const totalPriceHasChanged = game.reducedWeeks() || game.rowPriceChanged();
          const isChristmasThemeOn = controller.isActiveCampaignChristmas() && (controller.gameContainsExtraDraw() || game.upsellExtra?.());
          let iconsMap = {
            draw: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#lotto-draw',
            rows: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#lotto-rows',
            joker: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#lotto-joker',
            calendar: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#lotto-calendar',
            campaign: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#campaign_coupon',
            christmasCampaign: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#ticket_transparent'
          };
          if (isChristmasThemeOn) {
            iconsMap = {
              draw: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#confirm_draw',
              rows: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#confirm_rows',
              joker: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#joker_gold',
              calendar: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#confirm_calendar',
              campaign: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#normal_campaign_ticket_gold',
              christmasCampaign: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#ticket_gold_outline'
            };
          }

          const hasChristmasExtraDraws = () => {
            return controller.isActiveCampaignChristmas() && (controller.gameContainsExtraDraw() || game.upsellExtra?.());
          };

          const campaignCouponRow = () => {
            if (!game.campaignDrawsCount) {
              return null;
            }

            let label = '';
            let campaignDrawsCount = 0;
            if (controller.hasActiveCampaign()) {
              campaignDrawsCount = game.campaignDrawsCount(controller.activeCampaignModel().campaignNumbers);
              label = controller.d('ConfirmPage/CampaignRowsLabel', {
                campaignName: controller.activeCampaignModel().name
              });
            }
            if (settings.campaign) {
              campaignDrawsCount = game.campaignDrawsCount(settings.campaign);
              label = controller.d('ConfirmPage/CampaignRowsLabel', {
                campaignName: settings.campaign
              });
            }
            if (campaignDrawsCount === 0) {
              return null;
            }

            return m('.confirm-page__collapsible', {
              'data-uitest-id': 'Confirm__collapsible--campaign'
            }, m('.confirm-page__collapsible-top',
              m('.confirm-page__collapsible-top-row', [
                m('.confirm-page__collapsible-top-row-icon', m('svg.confirm-page__collapsible-icon-campaign', m('use', {
                  href: iconsMap.christmasCampaign
                }))),
                m('.confirm-page__collapsible-top-row-highlight-text', label),
                m('.confirm-page__collapsible-top-row-text', {
                  'data-uitest-id': 'Confirm__collapsible--campaign-rows'
                }, campaignDrawsCount * rows.length),
              ])
            ));
          };

          const extraCampaignsSection = () => {
            if (!controller.extraCampaignsData?.length) return null;
            const millionaireCampaign = controller.extraCampaignsData.find((campaign) => campaign.id === -1);
            const superMillionaireCampaign = controller.extraCampaignsData.find((campaign) => {
              return campaign.type === 'Accumulating' && campaign.name?.toLowerCase().includes('super');
            });

            return m('.confirm-page__extra-campaigns', [
              m('.confirm-page__extra-campaigns-headline', controller.d('MultiClient/ConfirmStep/ExtraCampaigns/Headline')),
              millionaireCampaign ? m('.confirm-page__collapsible', {
                'data-uitest-id': 'Confirm__collapsible--millionaire-campaign'
              }, m('.confirm-page__collapsible-top', m('.confirm-page__collapsible-top-row', [
                m('.confirm-page__collapsible-top-row-icon', m('svg.confirm-page__collapsible-icon-calendar', {
                  key: iconsMap.campaign
                }, m('use', {
                  href: iconsMap.campaign
                }))),
                m('.confirm-page__collapsible-top-row-highlight-text', [
                  m('div', controller.d('MultiClient/ConfirmStep/ExtraCampaigns/MillionaireChanceLabel')),
                  m('.confirm-page__collapsible-top-row-read-more', {
                    onclick: () => {
                      Event.fire('ds.event.accordionInfoOverlay:show', {
                        key: 'productchange',
                        srcIdentifier: controller.d('MultiClient/ConfirmStep/ExtraCampaigns/MillionaireChanceLabel')
                      });
                    }
                  }, controller.d('MultiClient/ConfirmStep/ExtraCampaigns/ReadMoreButton'))
                ]),
                m('.confirm-page__collapsible-top-row-text', controller.d('MultiClient/ConfirmStep/ExtraCampaigns/MillionaireChanceValue')),
              ]))) : null,
              superMillionaireCampaign ? m('.confirm-page__collapsible', {
                'data-uitest-id': 'Confirm__collapsible--super-millionaire-campaign'
              }, m('.confirm-page__collapsible-top', m('.confirm-page__collapsible-top-row', [
                m('.confirm-page__collapsible-top-row-icon', m('svg.confirm-page__collapsible-icon-calendar', {
                  key: iconsMap.campaign
                }, m('use', {
                  href: iconsMap.campaign
                }))),
                m('.confirm-page__collapsible-top-row-highlight-text', [
                  m('div', controller.d('MultiClient/ConfirmStep/ExtraCampaigns/SuperMillionaireChanceLabel')),
                  m('.confirm-page__collapsible-top-row-read-more', {
                    onclick: () => {
                      Event.fire('ds.event.accordionInfoOverlay:show', {
                        key: 'productchange',
                        srcIdentifier: controller.d('MultiClient/ConfirmStep/ExtraCampaigns/SuperMillionaireChanceLabel')
                      });
                    }
                  }, controller.d('MultiClient/ConfirmStep/ExtraCampaigns/ReadMoreButton'))
                ]),
                m('.confirm-page__collapsible-top-row-text', controller.d('MultiClient/ConfirmStep/ExtraCampaigns/SuperMillionaireChanceValue')),
              ]))) : null
            ]);
          };

          const christmasCampaignsSection = () => {
            const christmasExtraRowsCount = rows.length;
            let couponIcon = iconsMap.christmasCampaign;

            return m('.confirm-page__extra-campaigns', [
              m('.confirm-page__extra-campaigns-headline', controller.d('Campaigns/ChristmasMultiClient/ConfirmStep/TableSectionHeadline')),
              m('.confirm-page__collapsible', {
                'data-uitest-id': 'Confirm__collapsible--millionaire-campaign'
              }, m('.confirm-page__collapsible-top', m('.confirm-page__collapsible-top-row', [
                m('.confirm-page__collapsible-top-row-icon', m('svg.confirm-page__collapsible-icon-calendar', {
                  key: iconsMap.campaign
                }, m('use', {
                  href: iconsMap.campaign
                }))),
                m('.confirm-page__collapsible-top-row-highlight-text', [
                  m('div', controller.d('Campaigns/ChristmasMultiClient/ConfirmStep/TableSectionRowsLabel')),
                  m('.confirm-page__collapsible-top-row-read-more', {
                    onclick: () => {
                      Event.fire('ds.event.accordionInfoOverlay:show', {
                        key: 'productchange',
                        srcIdentifier: controller.d('Campaigns/ChristmasMultiClient/ConfirmStep/TableSectionRowsLabel'),
                        className: 'accordion-info-overlay__christmas-product-change'
                      });
                    }
                  }, controller.d('Campaigns/ChristmasMultiClient/ConfirmStep/ReadMoreButton'))
                ]),
                m('.confirm-page__collapsible-top-row-text', christmasExtraRowsCount),
              ]))),
              m('.confirm-page__collapsible', {
                'data-uitest-id': 'Confirm__collapsible--super-millionaire-campaign'
              }, m('.confirm-page__collapsible-top', m('.confirm-page__collapsible-top-row', [
                m('.confirm-page__collapsible-top-row-icon', m('svg.confirm-page__collapsible-icon-calendar', m('use', {
                  href: couponIcon
                }))),
                m('.confirm-page__collapsible-top-row-highlight-text', [
                  m('div', controller.d('Campaigns/ChristmasMultiClient/ConfirmStep/TableSectionCampaignLabel')),
                  m('.confirm-page__collapsible-top-row-read-more', {
                    onclick: () => {
                      Event.fire('ds.event.accordionInfoOverlay:show', {
                        key: 'productchange',
                        srcIdentifier: controller.d('MultiClient/ConfirmStep/ExtraCampaigns/SuperMillionaireChanceLabel'),
                        className: 'accordion-info-overlay__christmas-product-change'
                      });
                    }
                  }, controller.d('Campaigns/ChristmasMultiClient/ConfirmStep/ReadMoreButton'))
                ]),
                m('.confirm-page__collapsible-top-row-text', christmasExtraRowsCount),
              ])))
            ]);
          };

          const noticeInfoMessage = () => {
            let message = null;

            if (game.reducedWeeks() && !controller.gameContainsExtraDraw()) {
              message = controller.d('ConfirmPage/PriceChange/NoticeReducedWeeks', { ORIGINALDRAWS: game.originalNumberOfDraws(), DRAWS: game.numberOfDraws() });
            }

            if (game.rowPriceChanged() && !game.reducedWeeks() && !controller.gameContainsExtraDraw()) {
              message = controller.d('ConfirmPage/PriceChange/Notice');
            }

            if (game.rowPriceChanged() && controller.gameContainsExtraDraw() && !controller.gameContainsExtraDraw()) {
              message = controller.d('ConfirmPage/PriceChange/ExtraDrawChangeCount', { ORIGINALDRAWS: game.originalNumberOfDraws(), DRAWS: game.numberOfDraws() });
            }

            if (game.reducedWeeks() && game.originalExtraDraw()) {
              message = controller.d('ConfirmPage/PriceChange/NoticeReducedExtraDraw', { ORIGINALDRAWS: game.originalNumberOfDraws(), DRAWS: game.numberOfDraws() });
            }

            return message;
          };

          // Return:
          return m('.confirm-page__wrapper', {
            class: isChristmasThemeOn ? 'christmas-campaign-theme' : ''
          }, m('.confirm-page__content', {
            class: controller.gameStatusClass(),
            'data-uitest-id': 'Confirm__content'
          }, [
            // title.
            m('.confirm-page__title', m.trust(controller.d('ConfirmPage/Title', {
              playType: controller.d('PlayTypes/Lotto' + game.playType()),
              system: game.playType() === 'System' ? game.systemName() : ''
            }))),

            // campaign rows.
            controller.isActiveCampaignChristmas() ? null : campaignCouponRow(),

            // draw dates.
            gameDrawDates.length > 0 ? m('.confirm-page__collapsible.confirm-page__collapsible--can-open', {
              'data-uitest-id': 'Confirm__collapsible--draw-dates'
            }, [
              m('.confirm-page__collapsible-top', {
                onclick: controller.toggleCollapsible,
                'data-uitest-id': 'Confirm__collapsible--draw-dates-toggle'
              }, [
                m('.confirm-page__collapsible-top-row', [
                  m('.confirm-page__collapsible-top-row-icon',
                    m('svg.confirm-page__collapsible-icon-draw', {
                      key: iconsMap.draw
                    }, m('use', {
                      href: iconsMap.draw
                    }))
                  ),
                  m('.confirm-page__collapsible-top-row-highlight-text', controller.d('ConfirmPage/TableDraw')),
                  m('.confirm-page__collapsible-top-row-text', {
                    'data-uitest-id': 'Confirm__collapsible--draw-dates-date'
                  }, game.drawDateHtmlShort()),
                ])
              ]),
              m('.confirm-page__collapsible-bottom', [
                m('.confirm-page__table', [
                  gameDrawDates.map(function (date, i) {
                    return m('.confirm-page__table-row', {
                      class: date.hasJoker ? 'confirm-page__table-row--has-joker' : ''
                    }, [
                      m('.confirm-page__table-cell.confirm-page__table-cell--index', (i + 1) + '.'),
                      m('.confirm-page__table-cell', {
                        'data-uitest-id': 'Confirm__collapsible--draw-dates-date'
                      }, LottoUtils.getLongDate(date.draw))
                    ]);
                  })
                ])
              ])
            ]) : null,

            // rows.
            rows.length > 0 ? m('.confirm-page__collapsible.confirm-page__collapsible--can-open', {
              'data-uitest-id': 'Confirm__collapsible--rows'
            }, [
              m('.confirm-page__collapsible-top', {
                onclick: controller.toggleCollapsible,
                'data-uitest-id': 'Confirm__collapsible--rows-toggle'
              }, [
                m('.confirm-page__collapsible-top-row', [
                  m('.confirm-page__collapsible-top-row-icon',
                    m('svg.confirm-page__collapsible-icon-rows', {
                      key: iconsMap.rows
                    }, m('use', {
                      href: iconsMap.rows
                    }))
                  ),
                  m('.confirm-page__collapsible-top-row-highlight-text', controller.d('ConfirmPage/TableRows')),
                  m('.confirm-page__collapsible-top-row-text', {
                    'data-uitest-id': 'Confirm__collapsible--rows-rows'
                  }, rows.length),
                ])
              ]),
              m('.confirm-page__collapsible-bottom', [
                game.playType() === 'System'
                  ? [
                    m('.confirm-page__table-header', m.trust(controller.d('ConfirmPage/TableChosenSystemNumbers')) + ':'),
                    m('.confirm-page__table', [
                      m('.confirm-page__table-row',
                        m('.confirm-page__table-cell.confirm-page__table-cell--center', { 'data-uitest-id': 'Confirm__collapsible--rows-row' },
                          game.rows()[0].numbers.map(function (val) {
                            return val.number;
                          }).join(', ')
                        )
                      )
                    ])
                  ]
                  : m('.confirm-page__table', [
                    rows.map(function (val, i) {
                      return m('.confirm-page__table-row', [
                        m('.confirm-page__table-cell.confirm-page__table-cell--index', (i + 1) + '.'),
                        m('.confirm-page__table-cell', {
                          'data-uitest-id': 'Confirm__collapsible--rows-row'
                        }, val.join(', ')),

                        game.playType() === 'Classic' ?
                          m('.confirm-page__table-cell-icon-delete',
                            {
                              onclick: controller.deleteRow.bind(controller, i + 1),
                              'data-uitest-id': 'Confirm__collapsible--rows-classic-row-delete'
                            },
                            m('svg', m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-x' }))
                          ) : null,
                      ]);
                    })
                  ]),
              ])
            ]) : null,

            // jokers.
            rowsJokerWednesday.length > 0 || rowsJokerSaturday.length > 0 ? m('.confirm-page__collapsible.confirm-page__collapsible--can-open', {
              'data-uitest-id': 'Confirm__collapsible--jokers'
            }, [
              m('.confirm-page__collapsible-top', {
                onclick: controller.toggleCollapsible,
                'data-uitest-id': 'Confirm__collapsible--jokers-toggle'
              }, [
                m('.confirm-page__collapsible-top-row', [
                  m('.confirm-page__collapsible-top-row-icon',
                    m('svg.confirm-page__collapsible-icon-joker', {
                      key: iconsMap.joker
                    }, m('use', {
                      href: iconsMap.joker
                    }))
                  ),
                  m('.confirm-page__collapsible-top-row-highlight-text', controller.d('ConfirmPage/TableJoker')),
                  m('.confirm-page__collapsible-top-row-text',
                    {
                      'data-uitest-id': 'Confirm__collapsible--jokers-days'
                    }, rowsJokerWednesday.length > 0 && rowsJokerSaturday.length > 0
                      ? controller.d('ConfirmPage/TableJokerWednesdayAndSaturday')
                      : rowsJokerWednesday.length > 0 ? controller.d('ConfirmPage/TableJokerWednesday') : controller.d('ConfirmPage/TableJokerSaturday')
                  ),
                ])
              ]),
              m('.confirm-page__collapsible-bottom', [
                rowsJokerWednesday.length > 0 ? [
                  rowsJokerSaturday.length > 0 ? m('.confirm-page__table-header', m.trust(controller.d('ConfirmPage/TableJokerWednesday')) + ':') : '',
                  m('.confirm-page__table', [
                    rowsJokerWednesday.map(function (val, i) {
                      return m('.confirm-page__table-row', [
                        m('.confirm-page__table-cell.confirm-page__table-cell--index', (i + 1) + '.'),
                        m('.confirm-page__table-cell', {
                          'data-uitest-id': 'Confirm__collapsible--jokers-wednesday-row'
                        }, val.numbers.join(', '))
                      ]);
                    }),
                  ]),
                ] : null,

                rowsJokerSaturday.length > 0 ? [
                  rowsJokerWednesday.length > 0 ? m('.confirm-page__table-header', m.trust(controller.d('ConfirmPage/TableJokerSaturday')) + ':') : '',
                  m('.confirm-page__table', [
                    rowsJokerSaturday.map(function (val, i) {
                      return m('.confirm-page__table-row', [
                        m('.confirm-page__table-cell.confirm-page__table-cell--index', (i + 1) + '.'),
                        m('.confirm-page__table-cell', {
                          'data-uitest-id': 'Confirm__collapsible--jokers-saturday-row'
                        }, val.numbers.join(', '))
                      ]);
                    }),
                  ])
                ] : null

              ])
            ]) : null,

            // weeks.
            game.numberOfDraws()
              ? m('.confirm-page__collapsible', {
                'data-uitest-id': 'Confirm__collapsible--weeks'
              }, m('.confirm-page__collapsible-top', m('.confirm-page__collapsible-top-row', [
                m('.confirm-page__collapsible-top-row-icon', m('svg.confirm-page__collapsible-icon-calendar', {
                  key: iconsMap.calendar
                }, m('use', {
                  href: iconsMap.calendar
                }))),
                m('.confirm-page__collapsible-top-row-highlight-text', controller.d('ConfirmPage/TableWeeks')),
                m('.confirm-page__collapsible-top-row-text', {
                  'data-uitest-id': 'Confirm__collapsible--weeks-weeks'
                }, game.reducedWeeks() ?
                  [
                    m('span.confirm-page__crossed', game.originalNumberOfDraws()),
                    m('span', ' 🠊 '),
                    m('span', game.numberOfDraws()),
                  ] : game.numberOfDraws() + (game.upsellExtra?.() ? 1 : 0)),
              ])
              )
              ) : null,

            // Christmas campaigns:
            hasChristmasExtraDraws() > 0 ? christmasCampaignsSection() : null,

            // Extra campaigns:
            extraCampaignsSection(),

            // total.
            game.totalPrice() ? m('.confirm-page__total', {
              'data-uitest-id': 'Confirm__collapsible--total'
            }, [
              m('.confirm-page__total-label', controller.d('ConfirmPage/TableTotal')),
              m('.confirm-page__total-amount', {
                'data-uitest-id': 'Confirm__collapsible--total-amount'
              }, totalPriceHasChanged ?
                [
                  m('span.confirm-page__crossed', [
                    (rebuyCouponId.length === 19 && game.numberOfDraws() > 1 && game.firstWagerPrice()) ?
                      LottoUtils.formatCurrency(game.firstWagerPrice() * game.originalNumberOfDraws()) :
                      LottoUtils.formatCurrency(game.originalPrice())
                  ]),
                  m('span', ' 🠊 '),
                  m('span', LottoUtils.formatCurrency(game.totalPrice())),
                ] : LottoUtils.formatCurrency(game.totalPrice())),
            ]) : null,

            noticeInfoMessage() ? m('.confirm-page__info-message', noticeInfoMessage()) : null,

            // terms and conditions.
            m('.confirm-page__terms', {
              'data-uitest-id': 'Confirm__terms'
            }, [
              controller.d('ConfirmPage/GameVendor')
                ? controller.d('ConfirmPage/GameVendor')
                : null,

              controller.d('ConfirmPage/GameVendor') && controller.d('ConfirmPage/TermsLink') && controller.d('ConfirmPage/ViewTerms')
                ? m('span', ' - ') : '',

              controller.d('ConfirmPage/TermsLink') && controller.d('ConfirmPage/ViewTerms')
                ? m('a', {
                  href: controller.d('ConfirmPage/TermsLink')
                }, controller.d('ConfirmPage/ViewTerms'))
                : null
            ]),

            // show price notification.
            controller.showPrizeNotificationSection() ? ReceiveWinnerMessage(controller, 'lotto', '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoCommonIcons.svg#' + settings.iconUrl) : '',

            // confirm bar.
            ConfirmBar(controller),
          ])
          );
        }
      };

      // Setup routes:
      route('/', root);
    });

  });

defineDs('DanskeSpil/Domain/Lotto/Scripts/Components/MultiClient', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/AccountIntegration',
  'DanskeSpil/Domain/Lotto/Scripts/Helpers/SaturdayLottoApi',
  'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoDictionary',
  'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
  'DanskeSpil/Domain/Lotto/Scripts/Models/LottoGame',
  'DanskeSpil/Domain/Lotto/Scripts/Templates/LottoClassic/DeleteOverlay',
  'DanskeSpil/Domain/NumberGames/Scripts/Helpers/MultiClientDictionary',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/MultiClient/MultiClient'
], function (Component, AccountIntegration, Api, LottoDictionary, LottoInfo, LottoGame, DeleteOverlay, mcDictionary, MultiClient) {
  Component('lottosaturday-multi-client', [AccountIntegration, LottoDictionary, LottoInfo, LottoGame], function (m, ignoreRoute, settings, property, element) {
    this.d = LottoDictionary.get;
    this.settings = settings;
    this.property = property;
    this.element = element;
    this.gameType = 'Lotto';
    this.infoFeed = m.prop(LottoInfo.saturday());
    this.isFeedDown = m.prop(LottoInfo.isFeedDown());
    this.getLightningDefaultRowCount = m.prop(this.infoFeed().getLightningDefaultRowCount());
    this.getLightningOptions = m.prop(this.infoFeed().getLightningOptions);
    this.deleteOverlayForClassicRow = DeleteOverlay;
    this.api = function () {
      return Api;
    }.bind(this);

    this.isCampaignEngineOn = this.settings.useCampaignEngine;
    this.campaign = m.prop(this.settings.campaign);
    if (this.isCampaignEngineOn) {
      this.campaign(null);
    }

    var setItem = mcDictionary.setItem.bind(null, this.d);

    var lottoDictionaries = {
      closedGame: {
        noOpenDrawHeader: setItem('LottoClosed/FrontpageLightning/NoOpenDrawHeader'),
        noOpenDrawText1: setItem('LottoClosed/FrontpageLightning/NoOpenDrawText_1'),
        noOpenDrawText2: setItem('LottoClosed/FrontpageLightning/NoOpenDrawText_2'),
        gameNotAvailableHeader: setItem('LottoClosed/FrontpageLightning/GameNotAvailableHeader'),
        gameNotAvailableText1: setItem('LottoClosed/FrontpageLightning/GameNotAvailableText_1'),
        gameNotAvailableText2: setItem('LottoClosed/FrontpageLightning/GameNotAvailableText_2')
      },
      joker: {
        wednesdayJokerLabel: setItem('MultiClient/JokerStep/WednesdayJokerLabel'),
        saturdayJokerLabel: setItem('MultiClient/JokerStep/SaturdayJokerLabel'),
        bothJokerLabel: setItem('MultiClient/JokerStep/BothDrawsDaysLabel')
      },
      campaignInformOverlay: {
        title: setItem('Campaigns/ChristmasMultiClient/InformOverlay/Title'),
        body: setItem('Campaigns/ChristmasMultiClient/InformOverlay/Description'),
        confirmButton: setItem('Campaigns/ChristmasMultiClient/InformOverlay/ConfirmButton')
      },
      draws: {
        drawSublabelSingleWeekSingleTicket: setItem('MultiClient/DrawsStep/CampaignEngine/DrawSublabel'),
        drawSublabelSingleWeek: setItem('MultiClient/DrawsStep/CampaignEngine/DrawSublabel'),
        drawSublabelSingleTicket: setItem('MultiClient/DrawsStep/CampaignEngine/DrawSublabel'),
        drawSublabel: setItem('MultiClient/DrawsStep/CampaignEngine/DrawSublabel'),
        drawSublabelNoTickets: setItem('MultiClient/DrawsStep/CampaignEngine/DrawSublabelNoTickets')
      },
      receipt: {
        subscribeReminder: {
          keepRemindMe: setItem('LottoReceiptStep/SubscribeReminder/KeepRemindMe'),
          remindMe: setItem('LottoReceiptStep/SubscribeReminder/RemindMe'),
          errorUserUnauthorized: setItem('LottoReceiptStep/SubscribeReminder/ErrorUserUnauthorized'),
          error: setItem('LottoReceiptStep/SubscribeReminder/Error'),
          successRemindMe: setItem('LottoReceiptStep/SubscribeReminder/SuccessRemindMe'),
          successDontRemindMe: setItem('LottoReceiptStep/SubscribeReminder/SuccessDontRemindMe')
        }
      }
    };

    if (this.isCampaignEngineOn) {
      lottoDictionaries.game = {
        descriptionZero: setItem('MultiClient/GameStep/CampaignEngine/DescriptionZero'),
        descriptionSingle: setItem('MultiClient/GameStep/CampaignEngine/DescriptionSingle'),
        description: setItem('MultiClient/GameStep/CampaignEngine/Description')
      };
    }

    mcDictionary.setDictionary.bind(this.d)(lottoDictionaries);

    // Component
    MultiClient(m, LottoGame, this);
  });
});

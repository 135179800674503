defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/Components/TopSpotModal',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'Shared/Framework/Ensighten/Scripts/Ensighten',
  ], function (Component, Ensighten) {

    Component('top-spot-modal', function (ignoreM, route, settings, ignoreProperty, $element) {
      const root = {
        controller: function () {
          // variable

          const parentElement = $element.closest('.top-spot-responsive-frame'); // eslint-disable-line no-jquery/variable-pattern, no-jquery/no-closest -- Reason: Not a jquery selector
          const button = parentElement.querySelector('.top-spot-responsive-frame__cta button');

          if (button) {
            // adding link to button
            button.onclick = function (event) {
              event.preventDefault();
              Ensighten.pushGaEventOnPageRedirect('julelotto', 'topspot', 'click_to_flow');
              window.location.href = settings.cta?.url || '/';
            };
          }
        },
      };
      route('/', root);

    });
  });

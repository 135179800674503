defineDs('DanskeSpil/Domain/Lotto/Scripts/Components/GameClients/LottoClassicClient', [
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/Classic',
  'DanskeSpil/Domain/Lotto/Scripts/Models/LottoGame',
  'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
], function (Params, Event, NumberGamesClassic, LottoGame, LottoInfo) {

  // Client:
  // eslint-disable-next-line no-unused-vars
  var GameClient = function (m, settings, property) {

    // Components:
    var root = {
      controller: function () {

        this.settings = settings;
        this.property = property;

        // Variables:
        this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
        this.game = m.prop(LottoGame.setupGame({ gameInstanceId: this.gameInstanceId(), savedCoupon: settings.savedCoupon, playType: 'Classic' }));
        this.property('currentRow', settings.currentRow || 1);

        // Replace state:
        if (!this.gameInstanceId() || this.gameInstanceId() !== this.game().id()) {
          Params.set('gameInstanceId=' + this.game().id());
        }

        // Tracking - Push productDetails event to dataLayer
        this.game().trackingProductDetails();

        Event.subscribe('lotto:classic:createNewRow', () => this.property('callToCreateNewRow', true));
        Event.subscribe('numbersPicker:newGameInstanceGenerated', ({ id }) => {
          if (this.gameInstanceId() !== id) {
            Params.set('gameInstanceId=' + id);
          }
        });
      },

      view: function (controller) {
        return (<NumberGamesClassic
          rootSettings={settings}
          rootProperty={property}
          gameInstance={controller.game}
          gameModel={LottoGame}
          gameName={'lotto'}
          rowsOptions={LottoInfo.saturday().getClassicOptions()}
          defaultRowCount={LottoInfo.saturday().getClassicDefaultRowCount()}
        />);
      }
    };

    return root;

  };

  // Public functions:
  return GameClient;

});
